<template>
  <div id="manufacturer_catalogue">
    <div class="preview row" :style="returnBorder">
      <div v-if="defaultselection === false" class="models_wrapper filter row">
        <label for="categories">Категория</label>
        <select id="categories" v-model="selected_category">
          <option v-for="sel in categoryOptions" :key="sel.id" :value="sel.id">
            {{ sel.value }}
          </option>
        </select>
      </div>
      <div v-else class="models_wrapper filter row">
        <label for="section">Раздел</label>
        <select id="section" v-model="selected_section">
          <option v-for="sel in sectionsOptions" :key="sel.id" :value="sel.id">
            {{ sel.value }}
          </option>
        </select>
      </div>
      <div v-if="!emptyModel && !errorModel" class="models_wrapper row">
        <!-- Столбец -->
        <div
          v-for="(model, index) of filteredmodels"
          :key="model.name + index"
          :class="{
            'mcol-lg-3 mcol-md-4 mcol-sm-12 flex mcenter-xs':
              settings.col_number == 1,
            'mcol-lg-4 mcol-md-6 mcol-sm-12 flex mcenter-xs':
              settings.col_number == 2,
            'mcol-lg-6 mcol-md-6 mcol-sm-12 flex mcenter-xs':
              settings.col_number == 3,
            'mcol-lg-12 mcol-md-12 mcol-sm-12 flex mcenter-xs':
              settings.col_number == 4,
          }"
        >
          <a
            :href="`${url_site}/model/${model.name_alias}/${utm_source(
              model.id
            )}`"
            class="model-card d-block mb-md-6 mb-5"
            target="_blank"
            :class="{ 'last-el': index == filteredmodels.length - 1 }"
            :style="returnFont(settings.font)"
          >
            <div class="model-image_block">
              <img
                v-if="
                  model.link_picture !== '' &&
                  model.link_picture !== null &&
                  model.link_picture !== undefined
                "
                class="model-image"
                :src="`${url_site}${model.link_picture}`"
                :alt="model.name"
              />
              <img
                v-else
                class="model-image"
                :src="`${url_site}/dist/assets/front_white/img/svg/no-pic.svg`"
                alt="no picture"
              />
            </div>
            <p
              v-if="model.name.length <= 50"
              class="model-descr mx-6 fz14"
              :style="'color:' + settings.color_header"
            >
              {{ model.name }}
            </p>
            <p
              v-else
              class="model-descr mx-6 fz14"
              :style="'color:' + settings.color_header"
            >
              {{ model.name_short }}
            </p>
          </a>
        </div>
      </div>
      <div v-else-if="emptyModel && !errorModel" class="empty">
        <p>У вас еще не опубликовано ни одной модели</p>
      </div>
      <div
        v-else-if="(!emptyModel && errorModel) || (emptyModel && errorModel)"
        class="empty"
      >
        <p>Что-то пошло не так. Обратитесь в службу поддержки</p>
      </div>

      <!-- Пагинация -->
      <div
        v-if="
          !emptyModel &&
          !errorModel &&
          total_pages_models > 1 &&
          settings.card_number >= 1
        "
        class="pagination__manufacturer between-xs flex"
      >
        <div class="prev__page">
          <a href="Javascript:void(0);" class="link_arrow" @click="prevPage()">
            <span>&larr;</span>
          </a>
        </div>
        <div class="main__block flex">
          <div class="first__page">
            <a
              href="Javascript:void(0)"
              class="item"
              :class="{ active: page_model === 1 || page_model === 0 }"
              @click="changePage(1)"
              >1</a
            >
          </div>
          <div v-if="page_model > 5" class>
            <span class="dots">...</span>
          </div>
          <div v-for="(item, index) in total_pages_models" :key="index">
            <div v-if="checkpages(item)" class="central_part">
              <a
                class="item"
                :class="{ active: page_model === item }"
                href="Javascript:void(0)"
                @click="changePage(item)"
                >{{ item }}</a
              >
            </div>
          </div>
          <div v-if="page_model < total_pages_models - 4">
            <span class="dots">...</span>
          </div>
          <div v-if="total_pages_models" class="last__page">
            <a
              href="Javascript:void(0)"
              class="item"
              :class="{ active: page_model === total_pages_models }"
              @click="changePage(total_pages_models)"
              >{{ total_pages_models }}</a
            >
          </div>
        </div>
        <div class="next__page">
          <a href="Javascript:void(0);" class="link_arrow" @click="nextPage()">
            <span>&rarr;</span>
          </a>
        </div>
      </div>
      <!-- Подпись -->
      <p class="sign_bim">
        Работает на платформе
        <a
          :href="
            'https://bimlib.pro/?utm_source=embed&utm_medium=organic&utm_campaign=' +
            gethost()
          "
          target="_blank"
          >BIMLIB</a
        >
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/prop-name-casing */
/* eslint-disable no-undef */
/* eslint-disable vue/no-side-effects-in-computed-properties */
export default {
  name: 'ManufacturerCatalogue',
  props: {
    sec_key: {
      type: [Number, String],
      default: 0,
    },
    url_site: {
      type: String,
      default: 'https://bimlib.pro',
      // default: "http://newproject"
    },

    id_company: {
      type: String,
      default: '',
    },
    id1: {
      type: String,
      default: '0',
    },
    settings: {
      type: [Object, String],
      default: '',
    },
    LK: {
      type: Boolean,
      default: false,
    },
    test: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Models: [],
      current_models: [],
      categoryOptions: [],
      sectionsOptions: [],
      selected_category: 'all',
      selected_section: 'all',
      defaultselection: false,
      total_pages_models: 1,
      page_model: 1,
      hover_bg: '',
      hover_color: '',
      active: undefined,
      emptyModel: false,
      errorModel: false,
      first_load: true,
      currentUrl: '',
      utm: '/?utm_source=embed&utm_medium=organic&utm_campaign=',
    }
  },
  computed: {
    filteredmodels() {
      let modelss = []
      modelss = this.Models
      let oneArray = function (theArray) {
        theArray.splice(0, 0, null)
        return theArray
      }
      this.current_models = oneArray(
        _.chunk(modelss, this.settings.card_number)
      )
      if (this.current_models.length > 1) {
        this.total_pages_models = this.current_models.length - 1
      } else {
        this.total_pages_models = 0
      }
      return this.current_models[this.page_model]
    },
    returncolor() {
      return 'color:' + this.settings.color_text_hover
    },
    returnBg() {
      let color = this.settings.color_bg_hover.replace(/#/, '')
      let r = parseInt(color.substring(0, 2), 16)
      let g = parseInt(color.substring(2, 4), 16)
      let b = parseInt(color.substring(4, 6), 16)
      let result = 'rgba(' + r + ',' + g + ',' + b + ',' + '0.9' + ')'

      return 'background-color:' + result
    },
    returnBorder() {
      if (this.settings.showBorder === true) {
        return 'border: 1px solid #bdbdbd;'
      } else return 'border: 1px solid transparent;'
    },
  },
  watch: {
    'settings.defaultCategory'() {
      this.selected_category = this.settings.defaultCategory
    },
    'settings.defaultSection'() {
      this.selected_section = this.settings.defaultSection
    },
    'settings.defaultSelection'() {
      this.defaultselection = this.settings.defaultSelection
    },
    selected_category() {
      if (this.LK) {
        this.getData()
      } else {
        if (this.first_load === true) {
          this.first_load = false
        } else {
          this.getData()
        }
      }
    },
    selected_section() {
      if (this.LK) {
        this.getData()
      } else {
        if (this.first_load === true) {
          this.first_load = false
        } else {
          this.getData()
        }
      }
    },
  },
  mounted() {
    if (
      !(
        _.isEmpty(this.settings.defaultSection) ||
        this.settings.defaultSection === undefined
      )
    ) {
      this.selected_section = this.settings.defaultSection
    }
    if (
      !(
        _.isEmpty(this.settings.defaultCategory) ||
        this.settings.defaultCategory === undefined
      )
    ) {
      this.selected_category = this.settings.defaultCategory
    }
    if (
      !(
        _.isEmpty(this.settings.defaultSelection) ||
        this.settings.defaultSelection === undefined
      )
    ) {
      this.defaultselection = this.settings.defaultSelection
    }

    this.getData()
  },
  methods: {
    gethost() {
      return window.location.hostname
    },
    returnFont(val) {
      if (+val === 1) {
        return 'font-family: Lobster !important'
      }
      if (+val === 2) {
        return 'font-family: Lora !important'
      }
      if (+val === 3) {
        return 'font-family: Montserrat !important'
      }
      if (+val === 4) {
        return 'font-family: "Open Sans" !important'
      }
      if (+val === 5) {
        return 'font-family: Roboto !important'
      }
      if (+val === 6) {
        return 'font-family: Ubuntu !important'
      }
    },
    getData: function () {
      let Url
      if (!_.isEmpty(this.Models)) {
        this.Models = []
      }
      if (this.selected_category === 'all' && this.selected_section === 'all') {
        Url =
          this.url_site + '/web-api-2/embed/manufacturers?id=' + this.id_company
      } else {
        if (this.defaultselection === false) {
          Url =
            this.url_site +
            '/web-api-2/embed/manufacturers?id=' +
            this.id_company +
            '&category=' +
            this.selected_category
        } else {
          Url =
            this.url_site +
            '/web-api-2/embed/manufacturers?id=' +
            this.id_company +
            '&section=' +
            this.selected_section
        }
      }
      Promise.all([
        axios.get(this.url_site + '/web-api-2/objcategory/all'),
        axios.get(Url, { crossDomain: true }),
      ])
        .then(([categories, models]) => {
          if (_.isEmpty(this.categoryOptions)) {
            this.categoryOptions.push({
              id: 'all',
              value: 'Все',
            })
            if (!_.isEmpty(models.data.Result.Cats)) {
              categories.data.data.forEach((nod) => {
                if (models.data.Result.Cats.includes(nod.code)) {
                  this.categoryOptions.push({
                    id: nod.code,
                    value: nod.name,
                  })
                }
              })
            }
          }

          if (!_.isEmpty(models.data.Result.Models)) {
            this.emptyModel = false
            this.errorModel = false
            console.log(
              '1 models.data.Result.Models',
              models.data.Result.Models
            )
            models.data.Result.Models.forEach((nod) => {
              this.Models.push({
                id: nod.id,
                name: nod.name,
                name_short: nod.name_short,
                name_alias: nod.name_alias,
                link_picture: nod.link_picture,
              })
            })
          } else {
            this.emptyModel = true
            console.log('error get Models')
          }

          if (_.isEmpty(this.sectionsOptions)) {
            this.sectionsOptions = []
            this.sectionsOptions.push({
              id: 'all',
              value: 'Все',
            })
            if (!_.isEmpty(models.data.Result.Sections.rows))
              models.data.Result.Sections.rows.forEach((nod) => {
                this.sectionsOptions.push({
                  id: nod.id,
                  value: nod.name,
                })
              })
          }
        })
        .catch((err) => {
          console.error(err)
          this.categories = null
          this.models = null
        })
      this.first_load = false
    },
    utm_source(item) {
      if (this.test == false) {
        return (
          item + this.utm + this.id_company + '&utm_content=' + this.gethost()
        )
      } else return item
    },
    cropname(value) {
      return _.truncate(value, { length: 40, separator: ' ' })
    },
    cropdescription(value) {
      return _.truncate(value, { length: 200, separator: ' ' })
    },
    checkpages: function (item) {
      /*
       * функция получает каждую страницу и отобирает по условиям если true то елемент попадает на страницу
       * первая страница неотображаемая так как выведена статично как и последняя
       * нужно вывести 5 элементов от активной страницы
       */
      if (item === 1) {
        return false
      } // для первой страницы
      if (item === this.total_pages_models) {
        return false
      } // для последней страницы
      let start = this.page_model - 3 // старт = текущая страница минус реинж
      let end
      if (this.page_model === this.total_pages_models) {
        start = this.page_model - 7
      }
      if (this.page_model === this.total_pages_models - 1) {
        start = this.page_model - 6
      }
      if (this.page_model === this.total_pages_models - 2) {
        start = this.page_model - 5
      }
      if (this.page_model === this.total_pages_models - 3) {
        start = this.page_model - 4
      }
      if (this.page_model === this.total_pages_models - 4) {
        start = this.page_model - 3
      }
      if (item < 8) {
        end = this.page_model + 7 // конечная = текущая страница минус реинж
      } else {
        end = this.page_model + 3 // конечная = текущая страница минус реинж
      }
      if (start < item && end > item) {
        //3 < 8 и 13 > 8
        // console.log(item+'second');
        return true
      }
    },
    changePage: function (item) {
      if (this.page_model !== item) {
        this.page_model = item
      }
    },
    prevPage: function () {
      if (this.page_model !== 1) {
        this.page_model = this.page_model - 1
      }
    },
    nextPage: function () {
      if (this.page_model != this.total_pages_models) {
        this.page_model = this.page_model + 1
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lobster|Lora|Montserrat|Open+Sans|Roboto|Ubuntu');
@import './src/front_white/sass/components/settings';

.d-block {
  display: block;
}
@media screen and (min-width: 767.98px) {
  .mb-md-6 {
    margin-bottom: 14px !important;
  }
}
.fz14 {
  font-size: 14px;
}
// Карточка моделей
.model-card {
  position: relative;

  width: 12.1333rem;
  // min-width: 12.1333rem;
  height: 19.7333rem;
  // min-height: 19.7333rem;
  padding-bottom: 1.9333rem;
  overflow: hidden;

  background-color: $bg-main;
  border: solid 0.0667rem #ebebeb;
  border-radius: 0.3333rem;

  @media (min-width: $breakpoint-xs) and (max-width: width-max($breakpoint-md)) {
    width: 100%;
    min-width: 100%;
  }

  .download_model {
    width: 2.1333rem;
    height: 2.1333rem;
    border-radius: 0.3333rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0.9333rem;
    left: 0.9333rem;
    opacity: 0;
    background-color: $bg-main;
    z-index: 6;
    transition: all 0s;

    &:hover {
      i {
        color: $active;
        transition: all 0s;
      }
    }
  }

  // Серый фон при ховере
  // &:before {
  // 	content: '';
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;

  // 	width: 100%;
  // 	height: 100%;
  // 	// transform: scale(0);
  // 	opacity: 0;
  // 	z-index: 5;

  // 	background-color: rgba(227, 232, 235, 0.6);
  // }

  // Белый градиент снизу
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;

    width: 100%;
    height: 2.9333rem;

    background: linear-gradient(to bottom, rgba($bg-main, 0.3) 30%, $bg-main);
    z-index: 4;
  }

  &:hover {
    // .download_model {
    // 	opacity: 1;
    // }

    // &:before {
    // transform: scale(1);
    // 	opacity: 1;
    // }

    // box-shadow: 0 0.3333rem 0.6667rem 0 rgba(26, 26, 26, 0.05);
    // transform: translateY(-0.0667rem);
    border-color: $active;
  }

  &:active {
    border-color: rgba($active, 0.5);

    // box-shadow: none;
    // transform: translateY(0);
    // &::before {
    // 	background-color: rgba(227, 232, 235, 0.85);
    // }
  }
}

// Логотип компании-производителя на карточке
.model_manufacturer-logo {
  z-index: 2;

  img {
    z-index: 1;

    width: 1.8667rem;
    min-height: 1.8667rem;
    margin: 0.8667rem 0.7333rem 0 0;

    object-fit: contain;
  }
}

// Основной блок с изобрадением
.model-image_block {
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.7333rem;
  height: 9.6667rem;
  max-height: 9.6667rem;
  margin: -0.4667rem 1.2rem 0.7333rem;

  @media (min-width: $breakpoint-xs) and (max-width: width-max($breakpoint-md)) {
    width: 98px;
    height: 98px;
    max-height: 98px;
    margin: 0 auto;
  }

  .model-image {
    display: block;
    max-width: 100%;
    height: 9.6667rem;

    object-fit: contain;

    @media (min-width: $breakpoint-xs) and (max-width: width-max($breakpoint-md)) {
      height: 100%;
    }
  }
}

// Блок с названием модели
.model-descr {
  font-size: 0.9333rem;
}

.models_wrapper {
  justify-content: center;

  &.filter {
    flex-direction: column;
    /* align-content: flex-end; */
    margin-bottom: 50px;
  }
}
.model-card {
  /*max-height: 260px;*/
  max-height: 285px;
}
.model-image_block {
  margin-top: 1.2rem;
}

select,
input[type='tel'] {
  width: 260px !important;
  height: 30px;
  padding: 5px 10px;
  border: 1px solid #bdbdbd;
  /* border-bottom: 2px solid #bdbdbd; */
  border-radius: 8px;
  outline: none;
  transition: 0.3s;
  cursor: pointer;
}

.preview {
  position: relative;

  flex-direction: column !important;
  justify-content: center !important;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px;
  padding-bottom: 0;

  background-color: #ffffff;
  /* background: url('https://bimlib.pro/dist/assets/front_en/img/watermarkOne.png'); */
  border: 1px solid #bdbdbd;

  /* border-bottom: 2px solid #bdbdbd; */
  border-radius: 8px;
  outline: none;

  transition: 0.3s;

  &:hover {
    /* box-shadow: 0 3px 5px 0 #dfdfdf !important; */
  }

  .sign_bim {
    display: block;
    align-self: center;
    margin-top: 14px !important;
    margin-bottom: 20px !important;

    text-align: center;

    a {
      color: #00afa1;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

a {
  text-decoration: none !important;
}

.models {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;

  .model_img {
    img {
      max-width: 100%;
    }
  }

  .flex-col {
    margin-bottom: 20px;
  }

  .model_item {
    position: relative;

    width: 180px;
    margin: 0 auto;
    overflow: hidden;

    background-color: rgba(#ffffff, 0.6);
    border-radius: 10px;

    .hover_green_block {
      position: absolute;
      top: 0;
      left: 0;

      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 20px;

      background-color: rgba(#00afa1, 0.9);
      opacity: 0;

      .link_icon {
        a {
          position: relative;

          &:hover {
            &:before {
              background-color: white;
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: -3px;
            left: 0;

            width: 20px;
            height: 20px;

            background-color: none;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid white;
            border-radius: 4px;
          }
        }
      }

      .desc {
        overflow: hidden;

        font-weight: normal;
        font-size: 12px;
        line-height: 1.43;
        color: #ffffff;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        word-break: break-word;
      }
    }

    .opacity {
      opacity: 1;
    }

    .model_ico {
      margin-top: 5px;
      margin-bottom: 20px;

      a {
        margin-right: 25px;

        font-size: 12px;
        color: #333333;

        i {
          position: relative;
          top: 3px;

          margin-right: 6px;

          font-size: 17px;
        }
      }

      b {
        float: right;

        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #00afa1;
        text-transform: uppercase;
      }
    }

    .model_img {
      position: relative;

      .img_bg {
        img {
          width: 178px;
          height: 180px;

          border: 1px solid rgba(224, 224, 224, 0.28) !important;
          border-radius: 10px;

          -o-object-fit: contain;
          object-fit: contain;
        }
      }

      .img_manufacturer {
        position: absolute;
        right: 10px;
        bottom: 10px;

        img {
          width: auto;
          max-width: 90px;
          height: 50px;

          background-color: white;
          border: 1px solid #dddddd;
          border-radius: 10px;

          -o-object-fit: contain;
          object-fit: contain;
        }
      }
    }

    .model_name {
      margin-bottom: 9px;

      a {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        text-decoration: none;
      }
    }

    .model_company {
      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        color: #828282;

        span {
          color: #00afa1;
        }
      }
    }
  }
}

.pagination__manufacturer {
  /* width: 300px; */
  align-self: center;

  .dots {
    display: block;
    margin: 2px 5px 0 5px;
    padding: 5px;
  }

  .prev__page,
  .next__page {
    position: relative !important;

    display: flex !important;
    justify-content: center !important;
    width: 30px !important;
    width: 30px !important;
    height: 30px !important;

    border: 1px solid #bdbdbd;

    /* padding: 6px 7px 4px 7px; */
    border-radius: 4px;

    .link_arrow {
      display: flex !important;
      color: #bdbdbd !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100% !important;
      height: 100% !important;

      &:hover {
        span {
          color: #00afa1 !important;
        }
      }

      span {
        color: #bdbdbd !important;
        font-size: 20px !important;
        vertical-align: middle !important;
        height: 32px !important;
        line-height: 30px !important;
      }
    }

    &:hover {
      border: 1px solid #00afa1 !important;
    }
  }

  i {
    line-height: 16px !important;
    vertical-align: middle !important;
  }

  .central_part,
  .first__page,
  .last__page {
    .item {
      display: block;
      width: 30px;
      height: 30px;
      margin-right: 4px;
      margin-left: 4px;

      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      line-height: 30px;
      vertical-align: middle;
      text-align: center;
      color: #333333;

      border: 1px solid #ffffff;
      border-radius: 3px;
    }

    .item:hover {
      color: white !important;

      background: #00afa1;
    }

    .item.active {
      color: #00afa1;

      border: 1px solid #00afa1;
      border-radius: 4px;
    }
  }

  .first__page {
    .item {
      margin-left: 15px;
    }
  }

  .last__page {
    .item {
      margin-right: 15px;
    }
  }
}

.pagination__block {
  align-items: center;
  align-self: center;
  width: unset;
  margin-top: 97px;
  margin-bottom: 51px;

  .btn__block_next {
    .btn-standart-white {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px !important;
    }
  }
}

/*-------------------------------------- Grid --------------------------------------*/
.flex {
  display: flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.d-none {
  display: none;
}

.container-fluid,
.container {
  display: flex;
  flex-direction: column;
  width: 84.286rem;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 1 1 auto;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -ms-flex-wrap: wrap;
}

.mcol-xs,
.mcol-xs-1,
.mcol-xs-2,
.mcol-xs-3,
.mcol-xs-4,
.mcol-xs-5,
.mcol-xs-6,
.mcol-xs-7,
.mcol-xs-8,
.mcol-xs-9,
.mcol-xs-10,
.mcol-xs-11,
.mcol-xs-12,
.mcol-xs-offset-0,
.mcol-xs-offset-1,
.mcol-xs-offset-2,
.mcol-xs-offset-3,
.mcol-xs-offset-4,
.mcol-xs-offset-5,
.mcol-xs-offset-6,
.mcol-xs-offset-7,
.mcol-xs-offset-8,
.mcol-xs-offset-9,
.mcol-xs-offset-10,
.mcol-xs-offset-11,
.mcol-xs-offset-12 {
  position: relative;

  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  box-sizing: border-box;
  padding-right: 0.625rem;
  padding-left: 0.625rem;

  -webkit-box-flex: 0;
}

.mcol-xs {
  position: relative;

  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;

  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 0;
}

.mcol-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;

  -ms-flex-preferred-size: 8.33333333%;
}

.mcol-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;

  -ms-flex-preferred-size: 16.66666667%;
}

.mcol-xs-3 {
  flex-basis: 25%;
  max-width: 25%;

  -ms-flex-preferred-size: 25%;
}

.mcol-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;

  -ms-flex-preferred-size: 33.33333333%;
}

.mcol-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;

  -ms-flex-preferred-size: 41.66666667%;
}

.mcol-xs-6 {
  flex-basis: 50%;
  max-width: 50%;

  -ms-flex-preferred-size: 50%;
}

.mcol-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;

  -ms-flex-preferred-size: 58.33333333%;
}

.mcol-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;

  -ms-flex-preferred-size: 66.66666667%;
}

.mcol-xs-9 {
  flex-basis: 75%;
  max-width: 75%;

  -ms-flex-preferred-size: 75%;
}

.mcol-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;

  -ms-flex-preferred-size: 83.33333333%;
}

.mcol-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;

  -ms-flex-preferred-size: 91.66666667%;
}

.mcol-xs-12 {
  flex-basis: 100%;
  max-width: 100%;

  -ms-flex-preferred-size: 100%;
}

.mstart-xs {
  justify-content: flex-start;

  text-align: start;

  -webkit-box-pack: start;
  -ms-flex-pack: start;
}

.mcenter-xs {
  justify-content: center;

  text-align: center;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.mend-xs {
  justify-content: flex-end;

  text-align: end;

  -webkit-box-pack: end;
  -ms-flex-pack: end;
}

.mtop-xs {
  align-items: flex-start;

  -webkit-box-align: start;
  -ms-flex-align: start;
}

.mmiddle-xs {
  align-items: center;

  -webkit-box-align: center;
  -ms-flex-align: center;
}

.mbottom-xs {
  align-items: flex-end;

  -webkit-box-align: end;
  -ms-flex-align: end;
}

.maround-xs {
  justify-content: space-around;

  -ms-flex-pack: distribute;
}

.mbetween-xs {
  justify-content: space-between;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}

.mfirst-xs {
  -ms-flex-order: -1;
  order: -1;

  -webkit-box-ordinal-group: 0;
}

.mlast-xs {
  -ms-flex-order: 1;
  order: 1;

  -webkit-box-ordinal-group: 2;
}

@media only screen and (min-width: 560px) {
  .mcol-sm,
  .mcol-sm-1,
  .mcol-sm-2,
  .mcol-sm-3,
  .mcol-sm-4,
  .mcol-sm-5,
  .mcol-sm-6,
  .mcol-sm-7,
  .mcol-sm-8,
  .mcol-sm-9,
  .mcol-sm-10,
  .mcol-sm-11,
  .mcol-sm-12,
  .mcol-sm-offset-0,
  .mcol-sm-offset-1,
  .mcol-sm-offset-2,
  .mcol-sm-offset-3,
  .mcol-sm-offset-4,
  .mcol-sm-offset-5,
  .mcol-sm-offset-6,
  .mcol-sm-offset-7,
  .mcol-sm-offset-8,
  .mcol-sm-offset-9,
  .mcol-sm-offset-10,
  .mcol-sm-offset-11,
  .mcol-sm-offset-12 {
    position: relative;

    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    -webkit-box-flex: 0;
  }

  .mcol-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
  }

  .mcol-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;

    -ms-flex-preferred-size: 8.33333333%;
  }

  .mcol-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;

    -ms-flex-preferred-size: 16.66666667%;
  }

  .mcol-sm-3 {
    flex-basis: 25%;
    max-width: 25%;

    -ms-flex-preferred-size: 25%;
  }

  .mcol-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;

    -ms-flex-preferred-size: 33.33333333%;
  }

  .mcol-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;

    -ms-flex-preferred-size: 41.66666667%;
  }

  .mcol-sm-6 {
    flex-basis: 50%;
    max-width: 50%;

    -ms-flex-preferred-size: 50%;
  }

  .mcol-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;

    -ms-flex-preferred-size: 58.33333333%;
  }

  .mcol-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;

    -ms-flex-preferred-size: 66.66666667%;
  }

  .mcol-sm-9 {
    flex-basis: 75%;
    max-width: 75%;

    -ms-flex-preferred-size: 75%;
  }

  .mcol-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;

    -ms-flex-preferred-size: 83.33333333%;
  }

  .mcol-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;

    -ms-flex-preferred-size: 91.66666667%;
  }

  .mcol-sm-12 {
    flex-basis: 100%;
    max-width: 100%;

    -ms-flex-preferred-size: 100%;
  }

  .mcol-sm-offset-0 {
    margin-left: 0;
  }

  .mcol-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .mcol-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .mcol-sm-offset-3 {
    margin-left: 25%;
  }

  .mcol-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .mcol-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .mcol-sm-offset-6 {
    margin-left: 50%;
  }

  .mcol-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .mcol-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .mcol-sm-offset-9 {
    margin-left: 75%;
  }

  .mcol-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .mcol-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .mstart-sm {
    justify-content: flex-start;

    text-align: start;

    -webkit-box-pack: start;
    -ms-flex-pack: start;
  }

  .mcenter-sm {
    justify-content: center;

    text-align: center;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
  }

  .mend-sm {
    justify-content: flex-end;

    text-align: end;

    -webkit-box-pack: end;
    -ms-flex-pack: end;
  }

  .mtop-sm {
    align-items: flex-start;

    -webkit-box-align: start;
    -ms-flex-align: start;
  }

  .mmiddle-sm {
    align-items: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
  }

  .mbottom-sm {
    align-items: flex-end;

    -webkit-box-align: end;
    -ms-flex-align: end;
  }

  .maround-sm {
    justify-content: space-around;

    -ms-flex-pack: distribute;
  }

  .mbetween-sm {
    justify-content: space-between;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  }

  .mfirst-sm {
    -ms-flex-order: -1;
    order: -1;

    -webkit-box-ordinal-group: 0;
  }

  .mlast-sm {
    -ms-flex-order: 1;
    order: 1;

    -webkit-box-ordinal-group: 2;
  }
}

@media only screen and (min-width: 767px) {
  .mcol-md,
  .mcol-md-1,
  .mcol-md-2,
  .mcol-md-3,
  .mcol-md-4,
  .mcol-md-5,
  .mcol-md-6,
  .mcol-md-7,
  .mcol-md-8,
  .mcol-md-9,
  .mcol-md-10,
  .mcol-md-11,
  .mcol-md-12,
  .mcol-md-offset-0,
  .mcol-md-offset-1,
  .mcol-md-offset-2,
  .mcol-md-offset-3,
  .mcol-md-offset-4,
  .mcol-md-offset-5,
  .mcol-md-offset-6,
  .mcol-md-offset-7,
  .mcol-md-offset-8,
  .mcol-md-offset-9,
  .mcol-md-offset-10,
  .mcol-md-offset-11,
  .mcol-md-offset-12 {
    position: relative;

    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    -webkit-box-flex: 0;
  }

  .mcol-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
  }

  .mcol-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;

    -ms-flex-preferred-size: 8.33333333%;
  }

  .mcol-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;

    -ms-flex-preferred-size: 16.66666667%;
  }

  .mcol-md-3 {
    flex-basis: 25%;
    max-width: 25%;

    -ms-flex-preferred-size: 25%;
  }

  .mcol-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;

    -ms-flex-preferred-size: 33.33333333%;
  }

  .mcol-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;

    -ms-flex-preferred-size: 41.66666667%;
  }

  .mcol-md-6 {
    flex-basis: 50%;
    max-width: 50%;

    -ms-flex-preferred-size: 50%;
  }

  .mcol-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;

    -ms-flex-preferred-size: 58.33333333%;
  }

  .mcol-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;

    -ms-flex-preferred-size: 66.66666667%;
  }

  .mcol-md-9 {
    flex-basis: 75%;
    max-width: 75%;

    -ms-flex-preferred-size: 75%;
  }

  .mcol-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;

    -ms-flex-preferred-size: 83.33333333%;
  }

  .mcol-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;

    -ms-flex-preferred-size: 91.66666667%;
  }

  .mcol-md-12 {
    flex-basis: 100%;
    max-width: 100%;

    -ms-flex-preferred-size: 100%;
  }

  .mcol-md-offset-0 {
    margin-left: 0;
  }

  .mcol-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .mcol-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .mcol-md-offset-3 {
    margin-left: 25%;
  }

  .mcol-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .mcol-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .mcol-md-offset-6 {
    margin-left: 50%;
  }

  .mcol-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .mcol-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .mcol-md-offset-9 {
    margin-left: 75%;
  }

  .mcol-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .mcol-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .mstart-md {
    justify-content: flex-start;

    text-align: start;

    -webkit-box-pack: start;
    -ms-flex-pack: start;
  }

  .mcenter-md {
    justify-content: center;

    text-align: center;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
  }

  .mend-md {
    justify-content: flex-end;

    text-align: end;

    -webkit-box-pack: end;
    -ms-flex-pack: end;
  }

  .mtop-md {
    align-items: flex-start;

    -webkit-box-align: start;
    -ms-flex-align: start;
  }

  .mmiddle-md {
    align-items: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
  }

  .mbottom-md {
    align-items: flex-end;

    -webkit-box-align: end;
    -ms-flex-align: end;
  }

  .maround-md {
    justify-content: space-around;

    -ms-flex-pack: distribute;
  }

  .mbetween-md {
    justify-content: space-between;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  }

  .mfirst-md {
    -ms-flex-order: -1;
    order: -1;

    -webkit-box-ordinal-group: 0;
  }

  .mlast-md {
    -ms-flex-order: 1;
    order: 1;

    -webkit-box-ordinal-group: 2;
  }
}

@media only screen and (min-width: 996px) {
  .mcol-lg,
  .mcol-lg-1,
  .mcol-lg-2,
  .mcol-lg-3,
  .mcol-lg-4,
  .mcol-lg-5,
  .mcol-lg-6,
  .mcol-lg-7,
  .mcol-lg-8,
  .mcol-lg-9,
  .mcol-lg-10,
  .mcol-lg-11,
  .mcol-lg-12,
  .mcol-lg-offset-0,
  .mcol-lg-offset-1,
  .mcol-lg-offset-2,
  .mcol-lg-offset-3,
  .mcol-lg-offset-4,
  .mcol-lg-offset-5,
  .mcol-lg-offset-6,
  .mcol-lg-offset-7,
  .mcol-lg-offset-8,
  .mcol-lg-offset-9,
  .mcol-lg-offset-10,
  .mcol-lg-offset-11,
  .mcol-lg-offset-12 {
    position: relative;

    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    -webkit-box-flex: 0;
  }

  .mcol-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
  }

  .mcol-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;

    -ms-flex-preferred-size: 8.33333333%;
  }

  .mcol-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;

    -ms-flex-preferred-size: 16.66666667%;
  }

  .mcol-lg-3 {
    flex-basis: 25%;
    max-width: 25%;

    -ms-flex-preferred-size: 25%;
  }

  .mcol-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;

    -ms-flex-preferred-size: 33.33333333%;
  }

  .mcol-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;

    -ms-flex-preferred-size: 41.66666667%;
  }

  .mcol-lg-6 {
    flex-basis: 50%;
    max-width: 50%;

    -ms-flex-preferred-size: 50%;
  }

  .mcol-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;

    -ms-flex-preferred-size: 58.33333333%;
  }

  .mcol-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;

    -ms-flex-preferred-size: 66.66666667%;
  }

  .mcol-lg-9 {
    flex-basis: 75%;
    max-width: 75%;

    -ms-flex-preferred-size: 75%;
  }

  .mcol-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;

    -ms-flex-preferred-size: 83.33333333%;
  }

  .mcol-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;

    -ms-flex-preferred-size: 91.66666667%;
  }

  .mcol-lg-12 {
    flex-basis: 100%;
    max-width: 100%;

    -ms-flex-preferred-size: 100%;
  }

  .mcol-lg-offset-0 {
    margin-left: 0;
  }

  .mcol-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .mcol-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .mcol-lg-offset-3 {
    margin-left: 25%;
  }

  .mcol-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .mcol-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .mcol-lg-offset-6 {
    margin-left: 50%;
  }

  .mcol-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .mcol-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .mcol-lg-offset-9 {
    margin-left: 75%;
  }

  .mcol-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .mcol-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .mstart-lg {
    justify-content: flex-start;

    text-align: start;

    -webkit-box-pack: start;
    -ms-flex-pack: start;
  }

  .mcenter-lg {
    justify-content: center;

    text-align: center;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
  }

  .mend-lg {
    justify-content: flex-end;

    text-align: end;

    -webkit-box-pack: end;
    -ms-flex-pack: end;
  }

  .mtop-lg {
    align-items: flex-start;

    -webkit-box-align: start;
    -ms-flex-align: start;
  }

  .mmiddle-lg {
    align-items: center;

    -webkit-box-align: center;
    -ms-flex-align: center;
  }

  .mbottom-lg {
    align-items: flex-end;

    -webkit-box-align: end;
    -ms-flex-align: end;
  }

  .maround-lg {
    justify-content: space-around;

    -ms-flex-pack: distribute;
  }

  .mbetween-lg {
    justify-content: space-between;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  }

  .mfirst-lg {
    -ms-flex-order: -1;
    order: -1;

    -webkit-box-ordinal-group: 0;
  }

  .mlast-lg {
    -ms-flex-order: 1;
    order: 1;

    -webkit-box-ordinal-group: 2;
  }
}
</style>
