var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"manufacturer_catalogue"}},[_c('div',{staticClass:"preview row",style:(_vm.returnBorder)},[(_vm.defaultselection === false)?_c('div',{staticClass:"models_wrapper filter row"},[_c('label',{attrs:{"for":"categories"}},[_vm._v("Категория")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_category),expression:"selected_category"}],attrs:{"id":"categories"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected_category=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.categoryOptions),function(sel){return _c('option',{key:sel.id,domProps:{"value":sel.id}},[_vm._v("\n          "+_vm._s(sel.value)+"\n        ")])}),0)]):_c('div',{staticClass:"models_wrapper filter row"},[_c('label',{attrs:{"for":"section"}},[_vm._v("Раздел")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected_section),expression:"selected_section"}],attrs:{"id":"section"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected_section=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.sectionsOptions),function(sel){return _c('option',{key:sel.id,domProps:{"value":sel.id}},[_vm._v("\n          "+_vm._s(sel.value)+"\n        ")])}),0)]),_vm._v(" "),(!_vm.emptyModel && !_vm.errorModel)?_c('div',{staticClass:"models_wrapper row"},_vm._l((_vm.filteredmodels),function(model,index){return _c('div',{key:model.name + index,class:{
          'mcol-lg-3 mcol-md-4 mcol-sm-12 flex mcenter-xs':
            _vm.settings.col_number == 1,
          'mcol-lg-4 mcol-md-6 mcol-sm-12 flex mcenter-xs':
            _vm.settings.col_number == 2,
          'mcol-lg-6 mcol-md-6 mcol-sm-12 flex mcenter-xs':
            _vm.settings.col_number == 3,
          'mcol-lg-12 mcol-md-12 mcol-sm-12 flex mcenter-xs':
            _vm.settings.col_number == 4,
        }},[_c('a',{staticClass:"model-card d-block mb-md-6 mb-5",class:{ 'last-el': index == _vm.filteredmodels.length - 1 },style:(_vm.returnFont(_vm.settings.font)),attrs:{"href":`${_vm.url_site}/model/${model.name_alias}/${_vm.utm_source(
            model.id
          )}`,"target":"_blank"}},[_c('div',{staticClass:"model-image_block"},[(
                model.link_picture !== '' &&
                model.link_picture !== null &&
                model.link_picture !== undefined
              )?_c('img',{staticClass:"model-image",attrs:{"src":`${_vm.url_site}${model.link_picture}`,"alt":model.name}}):_c('img',{staticClass:"model-image",attrs:{"src":`${_vm.url_site}/dist/assets/front_white/img/svg/no-pic.svg`,"alt":"no picture"}})]),_vm._v(" "),(model.name.length <= 50)?_c('p',{staticClass:"model-descr mx-6 fz14",style:('color:' + _vm.settings.color_header)},[_vm._v("\n            "+_vm._s(model.name)+"\n          ")]):_c('p',{staticClass:"model-descr mx-6 fz14",style:('color:' + _vm.settings.color_header)},[_vm._v("\n            "+_vm._s(model.name_short)+"\n          ")])])])}),0):(_vm.emptyModel && !_vm.errorModel)?_c('div',{staticClass:"empty"},[_c('p',[_vm._v("У вас еще не опубликовано ни одной модели")])]):((!_vm.emptyModel && _vm.errorModel) || (_vm.emptyModel && _vm.errorModel))?_c('div',{staticClass:"empty"},[_c('p',[_vm._v("Что-то пошло не так. Обратитесь в службу поддержки")])]):_vm._e(),_vm._v(" "),(
        !_vm.emptyModel &&
        !_vm.errorModel &&
        _vm.total_pages_models > 1 &&
        _vm.settings.card_number >= 1
      )?_c('div',{staticClass:"pagination__manufacturer between-xs flex"},[_c('div',{staticClass:"prev__page"},[_c('a',{staticClass:"link_arrow",attrs:{"href":"Javascript:void(0);"},on:{"click":function($event){return _vm.prevPage()}}},[_c('span',[_vm._v("←")])])]),_vm._v(" "),_c('div',{staticClass:"main__block flex"},[_c('div',{staticClass:"first__page"},[_c('a',{staticClass:"item",class:{ active: _vm.page_model === 1 || _vm.page_model === 0 },attrs:{"href":"Javascript:void(0)"},on:{"click":function($event){return _vm.changePage(1)}}},[_vm._v("1")])]),_vm._v(" "),(_vm.page_model > 5)?_c('div',{},[_c('span',{staticClass:"dots"},[_vm._v("...")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.total_pages_models),function(item,index){return _c('div',{key:index},[(_vm.checkpages(item))?_c('div',{staticClass:"central_part"},[_c('a',{staticClass:"item",class:{ active: _vm.page_model === item },attrs:{"href":"Javascript:void(0)"},on:{"click":function($event){return _vm.changePage(item)}}},[_vm._v(_vm._s(item))])]):_vm._e()])}),_vm._v(" "),(_vm.page_model < _vm.total_pages_models - 4)?_c('div',[_c('span',{staticClass:"dots"},[_vm._v("...")])]):_vm._e(),_vm._v(" "),(_vm.total_pages_models)?_c('div',{staticClass:"last__page"},[_c('a',{staticClass:"item",class:{ active: _vm.page_model === _vm.total_pages_models },attrs:{"href":"Javascript:void(0)"},on:{"click":function($event){return _vm.changePage(_vm.total_pages_models)}}},[_vm._v(_vm._s(_vm.total_pages_models))])]):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"next__page"},[_c('a',{staticClass:"link_arrow",attrs:{"href":"Javascript:void(0);"},on:{"click":function($event){return _vm.nextPage()}}},[_c('span',[_vm._v("→")])])])]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"sign_bim"},[_vm._v("\n      Работает на платформе\n      "),_c('a',{attrs:{"href":'https://bimlib.pro/?utm_source=embed&utm_medium=organic&utm_campaign=' +
          _vm.gethost(),"target":"_blank"}},[_vm._v("BIMLIB")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }